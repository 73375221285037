










































































import { Vue, Component, Prop } from "vue-property-decorator";
import { MobilePaymentInfoProperties } from "./MobilePaymentInfoView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import DateUtil from '@/utilities/DateUtil';
import CustomerPaymentDetailService from "@/services/CustomerPaymentDetailService";

const MobilePaymentProductView = () => import("./MobilePaymentProductView.vue");

@Component({
    components: { MobilePaymentProductView },
    computed: {
        screenSmall: function() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        screenMedium: function() {
            return this.$vuetify.breakpoint.mdAndDown;
        }
    }
})
export default class CustomerPaymentDetailView extends Vue {
    @Prop() private properties: MobilePaymentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerPaymentDetailService = new CustomerPaymentDetailService();

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public formatAmount(v: any) {
        const symbol = this.properties.currency.symbol ?? "";
        return symbol + " " + v.toFixed(2);
    }

	public isExpanded(detail: any) {
		return detail.customerOrder.expanded;
	}

    public created() {
        this.load();
        this.properties.events.subscribe("load-details", this.load);
    }

    public destroyed() {
        this.properties.events.remove("load-details", this.load);
    }

    public async load() {
        try {
            const payment = this.properties.payment;
            if (payment.id) {
                const r = await this.customerPaymentDetailService.current({
                    customerPaymentId: payment.id,
                    loadCustomerOrder: true,
                });
                const details = r.data.customerPaymentDetails;
                this.properties.details = details.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.properties.details = [];
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public expand(detail: any) {
        const expanded = detail.customerOrder.expanded;
        Vue.set(detail.customerOrder, "expanded", !(expanded ?? false));
    }
}
